import Vue from 'vue';
import Router from 'vue-router';

// 避免加载重复页面时报错
// const originalPush = Router.prototype.push;
// Router.prototype.push = function push(location) {
//   return originalPush.call(this, location).catch(err => err)
// }

Vue.use(Router);

const routes = [
  // {
  //   path: '*',
  //   redirect: '/home'
  // },
  {
    // name: '/',
    path: '/',
    component: () => import('./view/index.vue'),
    meta: {
      title: 'Shenzhen-China International Furniture Exhibition & Creative Week',
    }
  },
  {
    name: 'home',
    path: '/home',
    component: () => import('./view/index.vue'),
    meta: {
      title: 'Shenzhen-China International Furniture Exhibition & Creative Week',
    }
  },
  {
    name: 'index',
    path: '/index',
    component: () => import('./view/index.vue'),
    meta: {
      title: 'Shenzhen-China International Furniture Exhibition & Creative Week',
    }
  },
  {
    name: 'business',
    path: '/business',
    component: () => import('./view/business.vue'),
    meta: {
      title: 'Shenzhen Furniture Exhibition: Service & Business Inquiries | Contact Us',
    }
  },
  {
    name: 'theme',
    path: '/theme',
    component: () => import('./view/theme.vue'),
    meta: {
      title: 'Shenzhen International Home Furnishing Exhibition: Themes',
    }
  },
  {
    name: 'exhibitor',
    path: '/exhibitor',
    component: () => import('./view/exhibitor.vue'),
    meta: {
      title: 'Shenzhen China World Exhibition Week: All Exhibitor Categories',
    }
  },
  {
    name: 'register',
    path: '/register',
    component: () => import('./view/register.vue'),
    meta: {
      title: 'Shenzhen International Furniture Fair: Visitor Pre-Registration 2025',
    }
  },
  {
    name: 'group',
    path: '/group',
    component: () => import('./view/group.vue'),
    meta: {
      title: 'SZ Creative Week - Group Registration, Benefits, Application T&Cs',
    }
  },
  {
    name: 'intro',
    path: '/intro',
    component: () => import('./view/intro.vue'),
    meta: {
      title: 'Shenzhen Creative Week: About the World Furniture Fair',
    }
  },
  {
    name: 'service',
    path: '/service',
    component: () => import('./view/service.vue'),
    meta: {
      title: 'Shenzhen-China Furniture Exhibition: Exclusive Services for Exhibitors',
    }
  },
  {
    name: 'traffic',
    path: '/traffic',
    component: () => import('./view/traffic.vue'),
    meta: {
      title: 'Shenzhen International Furniture Exhibition | Venue & Travel Info',
    }
  },
  {
    name: 'guide',
    path: '/guide',
    component: () => import('./view/guide.vue'),
    meta: {
      title: 'Shenzhen China World Furniture Exhibition & Creative Week | Guide Map',
    }
  },
  {
    name: 'history',
    path: '/history',
    component: () => import('./view/history.vue'),
    meta: {
      title: 'SZCW & SIFEChina 2025: Interational Home Furnishing Exhibition',
    }
  },
  {
    name: 'highlights',
    path: '/highlights',
    component: () => import('./view/highlights.vue'),
    meta: {
      title: 'SZ Creative Week | Highlights of 35th, 36th and 37th Edition',
    }
  },
  {
    name: 'library',
    path: '/library',
    component: () => import('./view/library.vue'),
    meta: {
      // title: '精彩资源',
      title: 'SZ Creative Week | Highlights of 35th, 36th and 37th Edition',
    }
  },
  // {
  //   name: 'competition',
  //   path: '/competition',
  //   component: () => import('./view/competition.vue'),
  //   meta: {
  //     title: '设计大赛',
  //   }
  // },
  {
    name: 'search',
    path: '/search',
    component: () => import('./view/search.vue'),
    meta: {
      title: 'search',
    }
  },
  {
    name: 'organizer',
    path: '/organizer',
    component: () => import('./view/organizer.vue'),
    meta: {
      title: 'Shenzhen Furniture Association - Organizer History | Estd. in 1986',
    }
  },
  {
    name: 'sourcing',
    path: '/sourcing',
    component: () => import('./view/sourcing.vue'),
    meta: {
      title: 'Shenzhen World Furniture Exhibition: Sourcing Request Form',
    }
  },
  {
    name: 'articles',
    path: '/articles',
    component: () => import('./view/articles.vue'),
    meta: {
      title: 'Shenzhen-China International Furniture Exhibition & Creative Week',
    }
  },
  {
    name: 'articleDetail',
    path: '/articleDetail',
    component: () => import('./view/articleDetail.vue'),
    meta: {
      title: 'Shenzhen-China International Furniture Exhibition & Creative Week',
    }
  },

];


const router = new Router({
  mode: 'history', // SEO: prerender-spa-plugin 预渲染时必须使用 history 模式
  routes,
  scrollBehavior () {
    return { x: 0, y: 0 } // 滚动到位置的坐标
  },
});

router.beforeEach((to, from, next) => {
  document.title = '【China Shenzhen Furniture Fair】China Shenzhen Creative Week and China  Shenzhen International Furniture Fair - Official Website'
  next();
});

export {
  router
};
