import 'amfe-flexible'; // 移动适配方案，自动计算html的 font-size 字体的大小
import Vue from 'vue';
import App from './App';
import './assets/style.css'
import { router } from './router';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

// 兼容桌面端的 mouse 事件、移动端的 touch 事件
// import '@vant/touch-emulator';

// SEO: 页面配置title 和 meta
import MetaInfo from 'vue-meta-info';

Vue.use(MetaInfo);
Vue.use(ElementUI);

new Vue({
  router,
  el: '#app',
  mounted () { // 等vue执行mounted生命周期后，表示页面已经挂载完成了，这是再触发自定义事件，告诉插件可以渲染了
    document.dispatchEvent(new Event('my-app-element'))
  },
  render: h => h(App)
});
